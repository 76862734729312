import React from 'react';
import './Sidebar.css';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import welcomeImage from '../assets/gym.jpeg';
import { useNavigate } from 'react-router-dom';
import './GymCard.css';

// Define the type for the subscription object
interface Subscription {
  id: number;
  type: string;
  price:number;
  duration:string;
  description:string;

}

const AddSubscription: React.FC = () => {
  const navigate = useNavigate(); // Call useNavigate hook

  // Sample data for subscription
  const subscriptions: Subscription[] = [
    {
      id: 1,
      type: 'Gold',
      price: 20,
      duration: '3 months',
      description: 'description'
    },
    {
      id: 2,
      type: 'Silver',
      price: 20,
      duration: '3 months',
      description: 'description'
    },
    {
      id: 3,
      type: 'Silver',
      price: 20,
      duration: '3 months',
      description: 'description'
    },
    {
      id: 4,
      type: 'Basic',
      price: 20,
      duration: '3 months',
      description: 'description'
    },
    {
      id: 5,
      type: 'Basic',
      price: 20,
      duration: '3 months',
      description: 'description'
    },
    {
      id: 6,
      type: 'Basic',
      price: 20,
      duration: '3 months',
      description: 'description'
    },

  ];

  return (
    <React.Fragment>
      {/* Header with search, button, icons */}
      <div className="header">
        <input type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/subscription-form')}>
            ADD SUBSCRIPTION
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>

      <div>
        <h2 className="flex justify-center text-2xl font-bold">Existing Subscription Plans</h2>
      </div>

{/* Subscription Table */}
<div className="overflow-x-auto mt-4">
  <table className="min-w-full border-collapse bg-white">
    <thead>
      <tr className="bg-gray-100 text-left">
        <th className="p-3 border-b-2">Plan Name</th>
        <th className="p-3 border-b-2">Duration</th>
        <th className="p-3 border-b-2">Price</th>
        <th className="p-3 border-b-2">Description</th>
        <th className="p-3 border-b-2 text-right">Action</th> {/* Align header to the right */}
      </tr>
    </thead>
    <tbody>
      {subscriptions.map((subscription) => (
        <tr key={subscription.id} className="border-b">
          <td className="p-3">{subscription.type}</td>
          <td className="p-3">{subscription.duration}</td>
          <td className="p-3">SAR{subscription.price}</td>
          <td className="p-3">{subscription.description}</td>
          <td className="p-3 text-right"> {/* Align buttons to the right */}
            <button
              className="text-blue-500 hover:text-blue-700 mr-2 border border-blue-500 px-3 py-1 rounded"
              onClick={() => navigate(`/subscription/edit/${subscription.id}`)}
            >
              Edit
            </button>
            <button
              className="text-red-500 hover:text-red-700 border border-red-500 px-3 py-1 rounded"
              onClick={() => console.log('Delete', subscription.id)}
            >
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

    </React.Fragment>
  );
};

export default AddSubscription;
