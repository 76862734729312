import React, { useState, ChangeEvent, FormEvent, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface SubscriptionData {
  SubscriptionType: string;
  price: number;
  Duration: string;
  Description : string;
}

interface Errors {
  SubscriptionType?: string;
  price?: string; // Updated to string for consistency in error messages
  Duration?: string;
  Description?: string;
}

function SubscriptionForm() {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [SubscriptionData, setSubscriptionData] = useState<SubscriptionData>({
    SubscriptionType: '',
    price: 0,
    Duration: '',
    Description : ''
  });

  const [errors, setErrors] = useState<Errors>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setSubscriptionData((prevData) => ({ ...prevData, [name]: value }));
      validate({ ...SubscriptionData, [name]: value });
    },
    [SubscriptionData]
  );

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate(SubscriptionData);
    },
    [SubscriptionData]
  );

  const validate = useCallback((data: SubscriptionData) => {
    let tempErrors: Errors = {};
    if (!data.SubscriptionType) tempErrors.SubscriptionType = 'Subscription Type is required';
    if (!data.Duration) tempErrors.Duration = 'Duration is required';
    if (data.price <= 0) tempErrors.price = 'Price must be greater than zero';

    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0);
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isFormValid) {
        return; // Don't submit if there are validation errors
      }

      console.log(SubscriptionData);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    },
    [isFormValid, SubscriptionData]
  );

  const handleCancel = useCallback(() => {
    navigate('/add-gym');
  }, [navigate]);

  return (
    <div className="container mx-auto p-10">
      <form className="bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-6">ADD NEW SUBSCRIPTION PLAN</h2>

        {success && <div className="bg-green-500 text-white p-3 rounded mb-4">SUCCESSFULLY SAVED</div>}

        {/* Subscription Plan */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
          Plan Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="SubscriptionType"
            value={SubscriptionData.SubscriptionType}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`basic-input border p-2 rounded ${errors.SubscriptionType && touched.SubscriptionType ? 'border-red-500' : ''}`}
          />
          {errors.SubscriptionType && touched.SubscriptionType && (
            <span className="text-red-500 text-sm">{errors.SubscriptionType}</span>
          )}
        </div>

        {/* Duration */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Duration <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="Duration"
            value={SubscriptionData.Duration}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`basic-input border p-2 rounded ${errors.Duration && touched.Duration ? 'border-red-500' : ''}`}
          />
          {errors.Duration && touched.Duration && (
            <span className="text-red-500 text-sm">{errors.Duration}</span>
          )}
        </div>

        {/* Description */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Description
          </label>
          <textarea
            name="Description"
            value={SubscriptionData.Description}
            className={`basic-input border p-2 rounded ${errors.Description && touched.Description ? 'border-red-500' : ''}`}
            rows={4} // Specify the number of rows
          />
          {errors.Description && touched.Description && (
            <span className="text-red-500 text-sm">{errors.Description}</span>
          )}
        </div>


        {/* Price */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">
            Price <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type="number"
              name="price"
              value={SubscriptionData.price}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`p-2 pl-12 border ${errors.price && touched.price ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full`}
              placeholder="Enter price"
            />
            <span className="absolute left-3 top-2 text-gray-500">SAR</span>
          </div>
          {errors.price && touched.price && (
            <span className="text-red-500 text-sm">{errors.price}</span>
          )}
        </div>

        {/* Buttons Section */}
        <div className="flex justify-start space-x-4">
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
            disabled={!isFormValid}
          >
            Save
          </button>

          <button
            type="button"
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default SubscriptionForm;
